/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const ReferralsForm = ({ inputs, setInputs, handleSubmit, loading }) => {
  return (
    <form onSubmit={handleSubmit} className="grid formgrid p-fluid">
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="nickname2" className="font-medium text-900">Client Name <span className="text-danger">*</span></label>
        <InputText id="nickname2" type="text" value={inputs.clientName} onChange={(ev) => setInputs({ ...inputs, clientName: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="bio2" className="font-medium text-900">Date of Birth <span className="text-danger">*</span></label>
        <Calendar value={inputs.dateOfBirth} onChange={(ev) => setInputs({ ...inputs, dateOfBirth: ev.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="nickname2" className="font-medium text-900">Address <span className="text-danger">*</span></label>
        <InputText id="nickname2" type="text" value={inputs.address} onChange={(ev) => setInputs({ ...inputs, address: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="nickname2" className="font-medium text-900">Phone Number <span className="text-danger">*</span></label>
        <InputText id="nickname2" type="tel" value={inputs.phoneNumber} onChange={(ev) => setInputs({ ...inputs, phoneNumber: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="nickname2" className="font-medium text-900">Gender Preferred <span className="text-danger">*</span></label>
        <InputText id="nickname2" type="text" value={inputs.genderPreferred} onChange={(ev) => setInputs({ ...inputs, genderPreferred: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="email2" className="font-medium text-900">Email</label>
        <InputText id="email2" type="email" value={inputs.email} onChange={(ev) => setInputs({ ...inputs, email: ev.target.value })} />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="email2" className="font-medium text-900">Living Situation <span className="text-danger">*</span></label>
        <InputText id="email2" type="text" value={inputs.livingSituation} onChange={(ev) => setInputs({ ...inputs, livingSituation: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="email2" className="font-medium text-900">Language Preferred <span className="text-danger">*</span></label>
        <InputText id="email2" type="text" value={inputs.languagePreferred} onChange={(ev) => setInputs({ ...inputs, languagePreferred: ev.target.value })} required />
      </div>
      <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="city2" className="font-medium text-900">Diagnosis</label>
        <InputTextarea id="city2" type="text" style={{ minHeight: '200px' }} value={inputs.diagnosis} onChange={(ev) => setInputs({ ...inputs, diagnosis: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="city2" className="font-medium text-900">Allergies <span className="text-danger">*</span></label>
        <InputTextarea id="city2" type="text" style={{ minHeight: '200px' }} value={inputs.allergies} onChange={(ev) => setInputs({ ...inputs, allergies: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="country2" className="font-medium text-900">Smoker?</label>
        <Dropdown
          inputId="smoker"
          options={[{ label: 'Yes' }, { label: 'No' }]}
          value={inputs.smoker}
          onChange={(ev) => setInputs({ ...inputs, smoker: ev.value })}
          placeholder="Select option"
          itemTemplate={(option) => (
            <div className="flex align-items-center">
              <div>{option.label}</div>
            </div>
          )}
        />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Agency/County <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.agency} onChange={(ev) => setInputs({ ...inputs, agency: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Case Manager Name <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.caseManagerName} onChange={(ev) => setInputs({ ...inputs, caseManagerName: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Case Manager Email <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.caseManagerEmail} onChange={(ev) => setInputs({ ...inputs, caseManagerEmail: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Case Manager Phone <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.caseManagerPhone} onChange={(ev) => setInputs({ ...inputs, caseManagerPhone: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="country2" className="font-medium text-900">Pets?</label>
        <Dropdown
          inputId="pets"
          options={[{ label: 'Yes' }, { label: 'No' }]}
          value={inputs.pets}
          onChange={(ev) => setInputs({ ...inputs, pets: ev.value })}
          placeholder="Select option"
          itemTemplate={(option) => (
            <div className="flex align-items-center">
              <div>{option.label}</div>
            </div>
          )}
        />
      </div>
      <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Emergency Contact/Guardian's Name <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.emergencyContactName} onChange={(ev) => setInputs({ ...inputs, emergencyContactName: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Emergency Contact/Guardian's Phone <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.emergencyContactPhone} onChange={(ev) => setInputs({ ...inputs, emergencyContactPhone: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Recent Hospitalizations? (in the last 6 months) <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.recentHospitalization} onChange={(ev) => setInputs({ ...inputs, recentHospitalization: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Language Preferred <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.languagePreferred2} onChange={(ev) => setInputs({ ...inputs, languagePreferred2: ev.target.value })} required />
      </div>
      <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Services Needed <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.servicesNeeded} onChange={(ev) => setInputs({ ...inputs, servicesNeeded: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="state2" className="font-medium text-900">Number of Hours/Week <span className="text-danger">*</span></label>
        <InputText id="state2" type="text" value={inputs.numberOfHoursOrWeeks} onChange={(ev) => setInputs({ ...inputs, numberOfHoursOrWeeks: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="bio2" className="font-medium text-900">Anticipated Start Date</label>
        <Calendar value={inputs.startDate} onChange={(ev) => setInputs({ ...inputs, startDate: ev.value })} />
      </div>
      <div className="field mb-4 col-12 md:col-6">
        <label htmlFor="city2" className="font-medium text-900">Goals/Outcome? <span className="text-danger">*</span></label>
        <InputTextarea id="city2" type="text" style={{ minHeight: '200px' }} value={inputs.goals} onChange={(ev) => setInputs({ ...inputs, goals: ev.target.value })} required />
      </div>
      <div className="field mb-4 col-12">
        <label htmlFor="city2" className="font-medium text-900">Comments <span className="text-danger">*</span></label>
        <InputTextarea id="city2" type="text" style={{ minHeight: '200px' }} value={inputs.comments} onChange={(ev) => setInputs({ ...inputs, comments: ev.target.value })} required />
      </div>
      <div className="surface-border border-top-1 opacity-50 mb-3 col-12" />
      <div className="col-12 text-center">
        <Button label="Submit Referral" type="submit" className="w-auto mt-3" loading={loading} />
      </div>
    </form>
  );
};

export default ReferralsForm;
