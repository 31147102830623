import React from 'react';
import Contact from '../components/contact/Contact';
import Layout from '../components/layout';
import Referrals from '../components/referrals/Referrals';
import Seo from '../components/seo';
import PageHeader from '../components/services/service-page/PageHeader';

const ReferralsPage = () => {
  return (
    <Layout>
      <Seo title="Referrals" />
      <PageHeader title="Referrals" />
      <Referrals />
      <Contact />
    </Layout>
  );
};

export default ReferralsPage;

export const Head = () => (
  <Seo title="Referrals" />
);
