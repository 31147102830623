import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { useAtom } from 'jotai';
import ReferralsForm from './ReferralsForm';
import { toastAtom } from '../layout';

const Referrals = () => {
  const [toast] = useAtom(toastAtom);
  const [inputs, setInputs] = useState({
    clientName: '',
    dateOfBirth: new Date(),
    address: '',
    phoneNumber: '',
    genderPreferred: '',
    email: '',
    livingSituation: '',
    languagePreferred: '',
    diagnosis: '',
    allergies: '',
    smoker: { label: 'Yes' },
    agency: '',
    caseManagerName: '',
    caseManagerEmail: '',
    caseManagerPhone: '',
    pets: { label: 'Yes' },
    emergencyContactName: '',
    emergencyContactPhone: '',
    recentHospitalization: '',
    languagePreferred2: '',
    servicesNeeded: '',
    numberOfHoursOrWeeks: '',
    startDate: new Date(),
    goals: ''
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      await firebase.firestore().collection('referrals').add({ ...inputs, timestamp: new Date(Date.now()) });
      setInputs({
        clientName: '',
        dateOfBirth: new Date(),
        address: '',
        phoneNumber: '',
        genderPreferred: '',
        email: '',
        livingSituation: '',
        languagePreferred: '',
        diagnosis: '',
        allergies: '',
        smoker: { label: 'Yes' },
        agency: '',
        caseManagerName: '',
        caseManagerEmail: '',
        caseManagerPhone: '',
        pets: { label: 'Yes' },
        emergencyContactName: '',
        emergencyContactPhone: '',
        recentHospitalization: '',
        languagePreferred2: '',
        servicesNeeded: '',
        numberOfHoursOrWeeks: '',
        startDate: new Date(),
        goals: '',
        comments: ''
      });
      setLoading(false);
      return toast.current?.show({ severity: 'success', summary: 'Successfully Submitted', detail: 'We\'ve received your referral and we will get back to you as soon as possible.', life: 6000 });
    } catch (error) {
      setLoading(false);
      console.log(error.message);
      return toast.current?.show({ severity: 'error', summary: 'Failed to submit', detail: error.message, life: 6000 });
    }
  };
  return (
    <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        Please fill out the form below and send it directly to us if you have any questions about our services or know of a client who would profit from working with Midway Homes. We'll get in touch straight away to talk about what comes next. Our greatest honor will be to assist you and your needs.
      </p>
      <div className="surface-card p-4 shadow-2 border-round">
        <ReferralsForm inputs={inputs} setInputs={setInputs} handleSubmit={handleSubmit} loading={loading} />
      </div>
    </div>
  );
};

export default Referrals;
